<script setup>
const props = defineProps({
  adsModel: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(["close"]);
</script>
<template>
  <div class="v-today-card">
    <div class="card-el mt-2">
      <div class="card-image-el">
        <img :src="adsModel?.ads_image" draggable="false" alt="bible lesson" />
      </div>
      <div class="card-inner-el">
        <v-btn class="close-btn" @click="emit('close')">
          <v-svg
            class="pa-1"
            src="/icons/close-icon.svg"
            :options="{ width: '20px', height: '20px', color: 'black' }"
          ></v-svg>
        </v-btn>
        <div>
          <div class="title">{{ transl("Bible School") }}</div>
          <p>{{ adsModel?.[`ads_desc`] }}</p>
        </div>
        <div class="d-grid mt-3 more-button">
          <NuxtLink :to="adsModel?.ads_url">
            <div class="card-button-el ml-auto">
              {{ transl("More details") }}
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.card-inner-el p {
  font-size: 14px;
  line-height: 18px;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: -15px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  font-size: 23px;
  line-height: 24px;
}
.v-today-card {
  /* font-family: "Golos Text"; */
  font-size: 16px;
}
.date-month-el {
  font-size: 16px;
  text-transform: uppercase;
}
.date-week-el {
  font-size: 34px;
}
.more-button a {
  width: fit-content;
}
.card-el {
  display: grid;
  grid-template-columns: 25% 75%;
  border-top: solid 1px #f1f1f1;
  padding: 24px 0;
  border-bottom: solid 1px #f1f1f1;
}

.card-el .card-image-el {
  overflow: hidden;
  max-height: 270px;
  aspect-ratio: 1 / 1.4;
}
.card-el .card-image-el img {
  /* max-width: 130px; */
  height: 100%;
  width: 100%;
  /* height: auto; */
  display: inline-block;
  object-fit: cover;
  border-radius: 8px;
}
.card-inner-el {
  padding: 5px 5px 5px 20px;
  min-height: 100px;
  line-height: 24px;
  font-family: "Golos Text";
  font-weight: 600;
  font-size: 14px;
  line-height: 107%;
  position: relative;
}
.card-inner-el p {
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  margin-top: 12px;
}
.card-button-el {
  font-family: "Golos Text";
  border-radius: 5px;
  background: var(--root-green);
  display: inline-flex;
  color: #fff;
  cursor: pointer;
  padding: 10px 12px;
  margin-right: 14%;

  font-weight: 600;
  font-size: 14px;
  line-height: 90%;
  text-align: center;
  box-shadow: 0 7px 12px 0 rgba(0, 191, 164, 0.25);
  white-space: nowrap;
}
@media (max-width: 500px) {
  .card-el {
    grid-template-columns: 100%;
    position: relative;
  }
  .card-image-el {
    margin: 0 auto;
    float: left;
    max-height: 300px;
  }
  .card-inner-el {
    position: static;
    margin-top: 20px;
    padding: 0;
  }
  .close-btn {
    top: 6px;
    right: 0;
  }
}
</style>
